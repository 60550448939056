<template>
    <div class="generic-widget-piechart">
        <div class="kt-portlet kt-portlet--height-fluid pieChart" :class="isDarkMode ? 'portletDark' : ''">
            <div class="kt-widget14">
                <div class="genericWidgetHeader">
                    <div class="kt-widget14__header">
                        <div class="kt-widget14__title genericWidgetTitle" :class="isDarkMode ? 'textWhiteForDark' : ''">
                            {{ this.widgetTitle }}
                        </div>
                        <div class="kt-widget14__desc genericWidgetTitle" :class="isDarkMode ? 'textWhiteForDark' : ''">
                            {{ $t(this.widgetDesc) }}
                        </div>
                    </div>
                    <div class="genericWidgetToolbar">
                        <a href="#" data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_drag')" class="btn btn-clean btn-icon btn-sm btn-icon-md moreButtonChartMain draggable-handle" :class="isDarkMode ? 'backgroundMoreBtnDarkHover' : ''">
                            <i class="flaticon2-cube-1" :class="isDarkMode ? 'textWhiteForDark' : ''"></i>
                        </a>
                        <div data-toggle="kt-tooltip" data-placement="top" :data-original-title="$t('common_moreActions')" class="dropdown dropdown-inline">
                            <button type="button" class="btn btn-clean btn-icon btn-sm btn-icon-md moreButtonChartMain" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="flaticon-more moreButtonChart" :class="isDarkMode ? 'textWhiteForDark' : ''"></i>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <a v-if="hasDetails" class="dropdown-item" href="#" @click="displayDetails"><i class="la la-line-chart"></i> {{ $t("analytics_details") }}</a>
                                <a class="dropdown-item" href="#" @click="onEditWidget"><i class="la la-cog"></i> {{ $t("analytics_settings") }}</a>
                                <a class="dropdown-item" href="#" @click="onDeleteWidget"><i class="la la-trash"></i> {{ $t("analytics_delete") }} </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kt-widget14__content genericWidgetContentChart">
                    <div class="kt-widget14__chart">
                        <div :id="'widget-chart-' + this.widgetId"></div>
                    </div>
                    <div class="kt-widget14__legends" :id="'widget-legend-' + this.widgetId"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "../../i18n";
export default {
    data() {
        //console.log("Component(widgetPieChart)::data() - called");
        return {
            widgetContentData: null,
            displayError: false,
            widgetTitle: null,
            widgetDesc: null,
            widgetType: null,
            hasDetails: false,
            colorPalette: ["#007bff", "#6610f2", "#6f42c1", "#e83e8c", "#dc3545", "#fd7e14", "#ffc107", "#28a745", "#20c997", "#17a2b8", "#5867dd", "#0abb87", "#5578eb", "#ffb822", "#fd397a"],
            refreshInterval: null,
            refreshSchedulerId: null,
            isOngoingFetch: false,
            perfectScrollBar: null
        };
    },
    created: function() {
        console.log("Component(widgetPieChart)::created() - called - widgetId: " + this.widgetId);
        this.colorPalette.sort(function() {
            return Math.random() - 0.5;
        });
        this.getWidgetTitle();
    },
    destroyed: function() {
        console.log("Component(widgetPieChart)::destroyed() - called - widgetId: " + this.widgetId);
        if (this.refreshSchedulerId) {
            clearInterval(this.refreshSchedulerId);
        }
        if (this.perfectScrollBar) {
            this.perfectScrollBar.destroy();
            this.perfectScrollBar = null;
        }
    },
    props: {
        widgetId: {
            type: String
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        isDarkMode: function(isDarkMode) {
            this.drawChart(this.isDarkMode);
        },

        currentDashboardWidgets: {
            //immediate: true,
            deep: true,
            handler(newValue) {
                this.getWidgetTitle(newValue);
            }
        },

        widgetsDrawingContentData: {
            //immediate: true,
            deep: true,
            handler(newValue) {
                var thisWidgetContent = this.getThisWidgetDrawingData(newValue);
                if (thisWidgetContent) {
                    this.widgetContentData = thisWidgetContent.drawingData || null;
                } else {
                    this.getWidgetContent(this.widgetId);
                }
            }
        },

        widgetContentData: {
            //immediate: true,
            deep: true,
            handler: function(newValue) {
                this.hasDetails = newValue && newValue.details ? true : false;
                this.drawChart(this.isDarkMode);
            }
        },

        currentDashboard: {
            immediate: true,
            deep: true,
            handler(newValue) {
                this.refreshInterval = newValue ? (parseInt(newValue.refreshInterval) || null) : null;
            }
        },

        refreshInterval: {
            immediate: true,
            handler(newValue) {
                console.log("Component(widgetPieChart)::watch(refreshInterval) handle with :", newValue);
                this.scheduleRefresh();
            }
        },

        widgetsLoadingState: {
            //deep: true,
            immediate: true,
            handler(newValue) {
                if (newValue.hasOwnProperty(this.widgetId)) {
                    this.isOngoingFetch = newValue[this.widgetId] ? true : false;
                } else if (this.refreshSchedulerId) {
                    // cancel scheduler
                }
            }
        },

        "$i18n.locale": function (v) {
            this.drawChart(this.isDarkMode);
        }

    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentDashboard", "currentDashboardWidgets", "widgetsDrawingContentData", "widgetsLoadingState", "isDarkMode"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["showDeleteModal", "getWidgetContent", "setWidgetToEdit", "setWidgetToDetail"]),

        getThisWidget: function(listWidgets) {
            var myWidget = (listWidgets || this.currentDashboardWidgets).filter(function(widgetObject) {
                return widgetObject.id === this.widgetId;
            }, this)[0];
            return myWidget;
        },

        getThisWidgetDrawingData: function(data) {
            return (data || this.widgetsDrawingContentData).filter(function(widgetContent) {
                return widgetContent.widgetId === this.widgetId;
            }, this)[0];
        },

        drawChart: function (isDarkMode) {
            if (this.widgetContentData && this.widgetContentData.summary) {
                var chartData = [];
                var availabilityColors = {
                    "ENUM_AVAILABILITY_AVAILABLE": "#8BC63F",
                    "ENUM_AVAILABILITY_UNAVAILABLE": "#ED1D24",
                    "NOT_APPLICABLE": "#6c757d"
                };
                var batteryColors = {
                    "HIGH_BATTERY": "#8BC63F",
                    "MEDIUM_BATTERY": "#ffc107",
                    "LOW_BATTERY": "#ED1D24",
                    "DISCHARGED_BATTERY": "#6c757d"
                }
                var colors = [];
                for (var item in this.widgetContentData.summary) {
                    if (this.widgetContentData.summary.hasOwnProperty(item)){
                        if (this.widgetType && this.widgetType === "NUMBER_OF_ASSETS_PER_CATEGORY") {
                            const itemDetail = this.widgetContentData.details[item];
                            chartData.push({
                                label: itemDetail && itemDetail.length > 0 && itemDetail[0].category.isDefault ? i18n.t("analytics_" + item) : item,
                                value: this.widgetContentData.summary[item]
                            });
                        }
                        else if (this.widgetType && this.widgetType === "NUMBER_OF_ASSETS_PER_UNIT") {
                            const itemDetail = this.widgetContentData.details[item];
                            chartData.push({
                                label: itemDetail && itemDetail.length > 0 && itemDetail[0].assetUnit.isDefault ? i18n.t("analytics_" + item) : item,
                                value: this.widgetContentData.summary[item]
                            });
                        }
                        else {
                            chartData.push({
                                label: i18n.t("analytics_" + item),
                                value: this.widgetContentData.summary[item]
                            });
                        }
                        if (this.widgetContentData.repartition === "availability") {
                            colors.push(availabilityColors[item]);
                        }
                        if (this.widgetContentData.repartition === "batteryLevel") {
                            colors.push(batteryColors[item]);
                        }
                    }
                }
                if (!colors.length) {
                    colors = this.colorPalette.slice(0, chartData.length);
                }

                $("#widget-chart-" + this.widgetId).empty().css({"height": "180px", "width": "180px"});
                try {
                    Morris.Donut({
                        element: "widget-chart-" + this.widgetId,
                        data: chartData,
                        colors: colors,
                        resize: true,
                        labelColor: isDarkMode ? "white" : "#343a40"
                    });
                } catch (error) {
                    console.log("Component(widgetPieChart)::drawChart() - error:", error)
                }

                if (isDarkMode){
                    $("#widget-chart-" + this.widgetId).closest(".kt-portlet").addClass("portletDark");
                    $("#widget-chart-" + this.widgetId).closest(".kt-portlet").find(".genericWidgetTitle").addClass("textWhiteForDark");
                    $("#widget-chart-" + this.widgetId).closest(".kt-portlet").find(".moreButtonChartMain").addClass("backgroundMoreBtnDarkHover");
                    $("#widget-chart-" + this.widgetId).closest(".kt-portlet").find(".moreButtonChart").addClass("textWhiteForDark");
                } else {
                    $("#widget-chart-" + this.widgetId).closest(".kt-portlet").removeClass("portletDark");
                    $("#widget-chart-" + this.widgetId).closest(".kt-portlet").find(".genericWidgetTitle").removeClass("textWhiteForDark");
                    $("#widget-chart-" + this.widgetId).closest(".kt-portlet").find(".moreButtonChartMain").removeClass("backgroundMoreBtnDarkHover");
                    $("#widget-chart-" + this.widgetId).closest(".kt-portlet").find(".moreButtonChart").removeClass("textWhiteForDark");
                }

                var legendContainer = $("#widget-legend-" + this.widgetId).empty().css({
                    "max-height": "180px",
                    "overflow": "hidden",
                    "max-width": "220px",
                    "font-family": "Poppins",
                    "position": "relative",
                    "padding-left": "5px"
                });

                for (var i = 0; i < chartData.length; i++) {
                    var legendLine = $(document.createElement("div")).addClass("kt-widget14__legend");
                    var legendColor = $(document.createElement("span")).addClass("kt-widget14__bullet").css("background-color", colors[i]).css("width", "10px important!");
                    var legendText = $(document.createElement("span")).addClass("kt-widget14__stats").text(chartData[i].label);
                    legendContainer.append(legendLine.append(legendColor, legendText));
                }

                if (this.perfectScrollBar) {
                    this.perfectScrollBar.update();
                } else {
                    this.perfectScrollBar = new PerfectScrollbar("#widget-legend-" + this.widgetId, {
                        wheelSpeed: 0.1,
                        wheelPropagation: true,
                        suppressScrollX: true
                    });
                }

                this.displayError = false;
                return;

            } else {
                this.displayError = true;
            }
        },

        onDeleteWidget: function () {
            this.showDeleteModal({ textContent: i18n.t("analytics_deleteWidgetConfirmText", { widgetName: this.widgetTitle }), actionName: "DELETE_WIDGET", params: { siteId: this.$route.params.siteId, dashboardId: this.$route.params.dashboardId, widgetId: this.widgetId } });
        },

        onEditWidget: function() {
            //console.log("Component(widgetPieChart)::onEditWidget - click on edit widget!");
            this.setWidgetToEdit(this.widgetId);
        },

        displayDetails() {
            this.setWidgetToDetail(this.widgetId);
        },

        scheduleRefresh: function() {
            if (this.refreshSchedulerId) {
                clearInterval(this.refreshSchedulerId);
            }
            this.smartFetchData();
            if (parseInt(this.refreshInterval)) {
                var self = this;
                this.refreshSchedulerId = setInterval(function() {
                    console.log("Component(widgetPieChart)::scheduleRefresh() -> widget " + self.widgetId + " setInterval called at " + self.refreshInterval + " seconds");
                    self.smartFetchData();
                }, parseInt(this.refreshInterval)*1000);
            }
        },

        smartFetchData() {
            if(!this.isOngoingFetch) {
                this.getWidgetContent(this.widgetId);
            }
        },

        formatWidgetTitle(thisWidget) {
            var isDefaultName = thisWidget.widgetType.defaultNameKey === thisWidget.customNameToDisplay;
            this.widgetTitle = isDefaultName ? i18n.t(thisWidget.customNameToDisplay) : thisWidget.customNameToDisplay;
        },

        getWidgetTitle(newValue) {
            const thisWidget = this.getThisWidget(newValue);
            if (thisWidget) {
                this.widgetType = thisWidget.widgetType.reference;
                this.formatWidgetTitle(thisWidget);
                this.widgetDesc = thisWidget.widgetType.defaultNameKey + "_desc";
            }
        }

    }
};
</script>
